export const isBrowser = () => typeof window !== 'undefined'

const WooCommerceRestApi = isBrowser()
  ? require('@woocommerce/woocommerce-rest-api').default
  : ''

const WP_API =
  process.env.NODE_ENV === 'production'
    ? process.env.GATSBY_CMS_URL
    : 'https://admin-ecoride.dev3.provideit.se'

const credentials = {
  wooKey: process.env.WOOCOMMERCE_CONSUMER_KEY
    ? process.env.WOOCOMMERCE_CONSUMER_KEY
    : 'ck_5ab34394fb512c3667b5d18faa0803f14d99b3cd',
  wooSecret: process.env.WOOCOMMERCE_CONSUMER_SECRET
    ? process.env.WOOCOMMERCE_CONSUMER_SECRET
    : 'cs_07207943d18cda850f24e31225c7191c2140671b',
}

const api = isBrowser()
  ? new WooCommerceRestApi({
      url: `${WP_API}`,
      consumerKey: credentials.wooKey,
      consumerSecret: credentials.wooSecret,
      version: 'wc/v3',
      queryStringAuth: true,
      axiosConfig: {
        headers: { Accept: 'application/json' },
      },
    })
  : {
      get: () => {
        return
      },
    }

export const getOrders = async user => {
  return await api
    .get(`orders?customer=${user}`)
    .then(res => res.data)
    .catch(err => err)
}

export const saveCustomerOrderIds = orders => {
  window.localStorage.setItem('orderids', JSON.stringify(orders))
}

export const getCustomerOrderIds = () =>
  isBrowser() && window.localStorage.getItem('orderids')
    ? JSON.parse(window.localStorage.getItem('orderids'))
    : {}
