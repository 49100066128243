/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('./src/styles/global.css')
const React = require('react')

const AppProvider = require('./src/config/context').AppProvider

exports.wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}
