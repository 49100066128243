import React, { createContext } from 'react'
import { useThunkReducer } from 'react-hook-thunk-reducer'
import { useStaticQuery, graphql } from 'gatsby'
import {
  getStockStatus,
  getTrustpilotReviews,
  getCustomerProfile,
  updateCustomerWP,
} from '../../helpers/api'
import { getOrders } from '../../services/orders'
/**
 * App context
 */
export const AppContext = createContext()

/**
 * Default state
 */
const storageCart =
  typeof window !== 'undefined' ? localStorage.getItem('productsInCart') : []

const parsedCart = (raw => {
  try {
    return JSON.parse(raw)
  } catch (err) {
    return []
  }
})(storageCart)

const storageCompare =
  typeof window !== 'undefined' ? localStorage.getItem('productsToCompare') : []

const orders =
  typeof window !== 'undefined'
    ? JSON.parse(localStorage.getItem('orders'))
    : []
const customer =
  typeof window !== 'undefined'
    ? JSON.parse(localStorage.getItem('customer'))
    : []
const user =
  typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : []

const parsedCompare = (raw => {
  try {
    return JSON.parse(raw)
  } catch (err) {
    return []
  }
})(storageCompare)

const initialState = {
  products: [],
  cartIsOpen: false,
  productsInCart: parsedCart ? parsedCart : [],
  compareIsEnabled: false,
  productsToCompare: parsedCompare ? parsedCompare : [],
  dealers: [],
  dealersFilterString: '',
  userPosition: null,
  bikeSelectorQuestions: [],
  stockStatus: [],
  trustpilot: [],
  selectedDealerId: null,
  customer: customer ? customer : [],
  orders: orders ? orders : [],
  user: user ? user : [],
  dealerFlag: false,
}

/**
 * Actions
 */
export const OPEN_CART = 'OPEN_CART'
export const openCart = () => ({
  type: OPEN_CART,
})

export const CLOSE_CART = 'CLOSE_CART'
export const closeCart = () => ({
  type: CLOSE_CART,
})

export const SET_USER = 'SET_USER'
export const setUser = user => ({
  type: SET_USER,
  payload: user,
})

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const addProductToCart = productId => ({
  type: ADD_PRODUCT_TO_CART,
  payload: { id: productId, quantity: 1 },
})

export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART'
export const removeProductFromCart = productId => ({
  type: REMOVE_PRODUCT_FROM_CART,
  payload: productId,
})

export const ADD_PRODUCT_TO_CART_AND_OPEN_CART =
  'ADD_PRODUCT_TO_CART_AND_OPEN_CART'
export const addProductToCartAndOpenCart = productId => {
  return (dispatch, getState) => {
    dispatch(addProductToCart(productId))
    dispatch(openCart())
    // setTimeout(() => {
    //   dispatch(openCart())
    // }, 100)
  }
}

export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY'
export const updateProductQuantity = (productId, quantity) => ({
  type: UPDATE_PRODUCT_QUANTITY,
  payload: { id: productId, quantity: quantity },
})

export const CLEAR_CART = 'CLEAR_CART'
export const clearCart = () => ({
  type: CLEAR_CART,
})

export const ENABLE_COMPARE = 'ENABLE_COMPARE'
export const enableCompare = () => ({
  type: ENABLE_COMPARE,
})

export const DISABLE_COMPARE = 'DISABLE_COMPARE'
export const disableCompare = () => ({
  type: DISABLE_COMPARE,
})

export const ADD_PRODUCT_TO_COMPARE = 'ADD_PRODUCT_TO_COMPARE'
export const addProductToCompare = productId => ({
  type: ADD_PRODUCT_TO_COMPARE,
  payload: productId,
})

export const REMOVE_PRODUCT_FROM_COMPARE = 'REMOVE_PRODUCT_FROM_COMPARE'
export const removeProductFromCompare = productId => ({
  type: REMOVE_PRODUCT_FROM_COMPARE,
  payload: productId,
})

export const SET_DEALER_FILTER_STRING = 'SET_DEALER_FILTER_STRING'
export const setDealerFilterString = filterString => ({
  type: SET_DEALER_FILTER_STRING,
  payload: filterString,
})

export const SET_SELECTED_DEALER_ID = 'SET_SELECTED_DEALER_ID'
export const setSelectedDealerId = dealerId => ({
  type: SET_SELECTED_DEALER_ID,
  payload: dealerId,
})

export const SET_USER_POSITION = 'SET_USER_POSITION'
export const setUserPosition = position => ({
  type: SET_USER_POSITION,
  payload: position,
})

export const DEALER_FLAG_TOGGLE = 'DEALER_FLAG_TOGGLE'
export const toggleDealerFlag = flag => ({
  type: DEALER_FLAG_TOGGLE,
  payload: flag,
})

export const SET_BIKE_SELECTOR_QUESTIONS = 'SET_BIKE_SELECTOR_QUESTIONS'
export const setBikeSelectorQuestions = questions => ({
  type: SET_BIKE_SELECTOR_QUESTIONS,
  payload: questions,
})

export const TOGGLE_BIKE_SELECTOR_ANSWER = 'TOGGLE_BIKE_SELECTOR_ANSWER'
export const toggleBikeSelectorAnswer = (questionIndex, alternativeIndex) => ({
  type: TOGGLE_BIKE_SELECTOR_ANSWER,
  payload: { questionIndex: questionIndex, alternativeIndex: alternativeIndex },
})

export const UPDATE_STOCK_STATUS = 'UPDATE_STOCK_STATUS'
export const updateStockStatus = stockStatus => ({
  type: UPDATE_STOCK_STATUS,
  payload: stockStatus,
})

export const DO_FETCH_STOCK_STATUS = 'DO_FETCH_STOCK_STATUS'
export const doFetchStockStatus = () => {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.stockStatus.length) {
      getStockStatus().then(response => dispatch(updateStockStatus(response)))
    }
  }
}

export const UPDATE_TRUSTPILOT_REVIEWS = 'UPDATE_TRUSTPILOT_REVIEWS'
export const updateTrustpilot = reviews => ({
  type: UPDATE_TRUSTPILOT_REVIEWS,
  payload: reviews,
})

export const DO_FETCH_TRUSTPILOT_REVIEWS = 'DO_FETCH_TRUSTPILOT_REVIEWS'
export const doFetchTrustpilotReviews = () => {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.trustpilot.length) {
      getTrustpilotReviews().then(response =>
        dispatch(updateTrustpilot(response))
      )
    }
  }
}

export const FETCH_CUSTOMER_DATA = 'FETCH_CUSTOMER_DATA'
export const getCustomerData = userId => {
  return (dispatch, getState) => {
    const state = getState()
    getCustomerProfile(userId).then(response =>
      dispatch(setCustomerProfile(response))
    )
  }
}

export const CUSTOMER_DATA = 'CUSTOMER_DATA'
export const setCustomerProfile = customer => ({
  type: CUSTOMER_DATA,
  payload: customer,
})

export const CUSTOMER_ORDERS = 'CUSTOMER_ORDERS'
export const setCustomerOrders = orders => ({
  type: CUSTOMER_ORDERS,
  payload: orders,
})

export const FETCH_CUSTOMER_ORDERS = 'FETCH_CUSTOMER_ORDERS'
export const getCustomerOrders = userId => {
  return (dispatch, getState) => {
    const state = getState()
    if (!Object.keys(state.orders).length || !state.orders.length) {
      return getOrders(userId).then(response => {
        if (!response.name && response.name != 'Error') {
          dispatch(setCustomerOrders(response))
        }
      })
    }
  }
}

export const UPDATE_CUSTOMER_PROFILE = 'UPDATE_CUSTOMER_PROFILE'
export const updateCustomerProfile = customer => {
  return (dispatch, getState) => {
    return updateCustomerWP(customer)
  }
}

/**
 * Reducer
 */
const reducer = (state, action) => {
  let updatedProductsInCart
  let updatedProductsToCompare

  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case OPEN_CART:
      return {
        ...state,
        cartIsOpen: true,
      }
    case SET_SELECTED_DEALER_ID:
      return {
        ...state,
        selectedDealerId: action.payload,
      }

    case CLOSE_CART:
      return {
        ...state,
        cartIsOpen: false,
      }

    case ADD_PRODUCT_TO_CART:
      let alreadyInCart = state.productsInCart.reduce((acc, product) => {
        return product.id === action.payload.id ? true : acc
      }, false)

      updatedProductsInCart = !alreadyInCart
        ? [...state.productsInCart, action.payload]
        : state.productsInCart

      localStorage.setItem(
        'productsInCart',
        JSON.stringify(updatedProductsInCart)
      )

      return {
        ...state,
        productsInCart: updatedProductsInCart,
      }

    case REMOVE_PRODUCT_FROM_CART:
      updatedProductsInCart = state.productsInCart.filter(
        product => product.id !== action.payload
      )

      localStorage.setItem(
        'productsInCart',
        JSON.stringify(updatedProductsInCart)
      )

      return {
        ...state,
        productsInCart: updatedProductsInCart,
      }

    case UPDATE_PRODUCT_QUANTITY:
      updatedProductsInCart = state.productsInCart.map(product => {
        return product.id === action.payload.id
          ? { id: product.id, quantity: action.payload.quantity }
          : product
      })

      localStorage.setItem(
        'productsInCart',
        JSON.stringify(updatedProductsInCart)
      )

      return {
        ...state,
        productsInCart: updatedProductsInCart,
      }

    case CLEAR_CART:
      localStorage.removeItem('productsInCart')
      return {
        ...state,
        productsInCart: [],
      }

    case ENABLE_COMPARE:
      return {
        ...state,
        compareIsEnabled: true,
      }

    case DISABLE_COMPARE:
      return {
        ...state,
        compareIsEnabled: false,
      }

    case ADD_PRODUCT_TO_COMPARE:
      updatedProductsToCompare = !state.productsToCompare.includes(
        action.payload
      )
        ? [...state.productsToCompare, action.payload]
        : state.productsToCompare

      if (updatedProductsToCompare.length <= 4) {
        localStorage.setItem(
          'productsToCompare',
          JSON.stringify(updatedProductsToCompare)
        )
      }

      return updatedProductsToCompare.length <= 4
        ? {
            ...state,
            productsToCompare: updatedProductsToCompare,
          }
        : { ...state }

    case REMOVE_PRODUCT_FROM_COMPARE:
      updatedProductsToCompare = state.productsToCompare.filter(
        id => id !== action.payload
      )

      localStorage.setItem(
        'productsToCompare',
        JSON.stringify(updatedProductsToCompare)
      )

      return {
        ...state,
        productsToCompare: updatedProductsToCompare,
      }

    case SET_DEALER_FILTER_STRING:
      return {
        ...state,
        dealersFilterString: action.payload,
      }

    case SET_USER_POSITION:
      return {
        ...state,
        userPosition: action.payload,
      }
    case UPDATE_STOCK_STATUS:
      return {
        ...state,
        stockStatus: action.payload,
      }
    case UPDATE_TRUSTPILOT_REVIEWS:
      return {
        ...state,
        trustpilot: action.payload,
      }

    case CUSTOMER_DATA:
      localStorage.setItem('customer', JSON.stringify(action.payload))
      return {
        ...state,
        customer: action.payload,
      }

    case CUSTOMER_ORDERS:
      localStorage.setItem('orders', JSON.stringify(action.payload))
      return {
        ...state,
        orders: action.payload,
      }
    case DEALER_FLAG_TOGGLE:
      return {
        ...state,
        dealerFlag: action.payload,
      }

    case SET_BIKE_SELECTOR_QUESTIONS:
      // Add checked property to bikeSelectorQuestions alternatives
      const parsedBikeSelectorQuestions = action.payload.reduce(
        (acc, question) => {
          const parsedAnswerAlternatives = question.answerAlternatives.map(
            alternative => {
              return { ...alternative, checked: false }
            }
          )
          question.answerAlternatives = parsedAnswerAlternatives

          return [...acc, question]
        },
        []
      )

      return {
        ...state,
        bikeSelectorQuestions: parsedBikeSelectorQuestions,
      }

    case TOGGLE_BIKE_SELECTOR_ANSWER:
      const { questionIndex, alternativeIndex } = action.payload

      let updatedBikeSelectorQuestions = state.bikeSelectorQuestions.map(
        (question, qIndex) => {
          if (qIndex === questionIndex) {
            let updatedAnswerAlternatives = question.answerAlternatives.map(
              (alternative, aIndex) => {
                return {
                  ...alternative,
                  checked: aIndex === alternativeIndex,
                }
              }
            )
            return {
              ...question,
              answerAlternatives: updatedAnswerAlternatives,
            }
          } else {
            return question
          }
        }
      )
      return {
        ...state,
        bikeSelectorQuestions: updatedBikeSelectorQuestions,
      }

    default:
      return state
  }
}

/**
 * Provider
 */
export const AppProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query ContextQuery {
      wordpress {
        products(first: 200) {
          nodes {
            ...List_Product
          }
        }
      }
    }
  `)

  const products = data.wordpress.products.nodes

  // Filter out old productIds in compare
  let filteredProductsToCompare = initialState.productsToCompare.filter(id =>
    hasMatchingProduct(id, products)
  )
  initialState.productsToCompare = filteredProductsToCompare

  // And in cart
  let filteredProductsInCart = initialState.productsInCart.filter(product => {
    return product && product.id
      ? hasMatchingProduct(product.id, products)
      : false
  })
  initialState.productsInCart = filteredProductsInCart

  const [state, dispatch] = useThunkReducer(reducer, initialState)
  return (
    <AppContext.Provider
      value={[
        {
          ...state,
          products: products,
        },
        dispatch,
      ]}
    >
      {children}
    </AppContext.Provider>
  )
}

const hasMatchingProduct = (id, products) => {
  let found = false

  for (var i = 0; i < products.length; i++) {
    if (products[i].productId === id) {
      found = true
      break
    }

    if (
      products[i].variations &&
      products[i].variations.nodes &&
      products[i].variations.nodes.length > 0
    ) {
      for (var j = 0; j < products[i].variations.nodes.length; j++) {
        if (products[i].variations.nodes[j].variationId === id) {
          found = true
          break
        }
      }
    }
  }

  return found
}
