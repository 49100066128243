// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-draft-js": () => import("./../../../src/pages/draft.js" /* webpackChunkName: "component---src-pages-draft-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-templates-bike-selector-bike-selector-questions-js": () => import("./../../../src/templates/bike-selector/bike-selector-questions.js" /* webpackChunkName: "component---src-templates-bike-selector-bike-selector-questions-js" */),
  "component---src-templates-bike-selector-bike-selector-result-js": () => import("./../../../src/templates/bike-selector/bike-selector-result.js" /* webpackChunkName: "component---src-templates-bike-selector-bike-selector-result-js" */),
  "component---src-templates-bike-selector-bike-selector-start-js": () => import("./../../../src/templates/bike-selector/bike-selector-start.js" /* webpackChunkName: "component---src-templates-bike-selector-bike-selector-start-js" */),
  "component---src-templates-dealers-dealers-city-listing-js": () => import("./../../../src/templates/dealers/dealers-city-listing.js" /* webpackChunkName: "component---src-templates-dealers-dealers-city-listing-js" */),
  "component---src-templates-dealers-dealers-country-listing-js": () => import("./../../../src/templates/dealers/dealers-country-listing.js" /* webpackChunkName: "component---src-templates-dealers-dealers-country-listing-js" */),
  "component---src-templates-dealers-dealers-map-js": () => import("./../../../src/templates/dealers/dealers-map.js" /* webpackChunkName: "component---src-templates-dealers-dealers-map-js" */),
  "component---src-templates-dealers-single-dealer-js": () => import("./../../../src/templates/dealers/single-dealer.js" /* webpackChunkName: "component---src-templates-dealers-single-dealer-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-posts-post-category-js": () => import("./../../../src/templates/posts/post-category.js" /* webpackChunkName: "component---src-templates-posts-post-category-js" */),
  "component---src-templates-posts-post-listing-js": () => import("./../../../src/templates/posts/post-listing.js" /* webpackChunkName: "component---src-templates-posts-post-listing-js" */),
  "component---src-templates-posts-single-post-js": () => import("./../../../src/templates/posts/single-post.js" /* webpackChunkName: "component---src-templates-posts-single-post-js" */),
  "component---src-templates-products-product-category-js": () => import("./../../../src/templates/products/product-category.js" /* webpackChunkName: "component---src-templates-products-product-category-js" */),
  "component---src-templates-products-product-comparison-js": () => import("./../../../src/templates/products/product-comparison.js" /* webpackChunkName: "component---src-templates-products-product-comparison-js" */),
  "component---src-templates-products-product-listing-js": () => import("./../../../src/templates/products/product-listing.js" /* webpackChunkName: "component---src-templates-products-product-listing-js" */),
  "component---src-templates-products-single-product-js": () => import("./../../../src/templates/products/single-product.js" /* webpackChunkName: "component---src-templates-products-single-product-js" */),
  "component---src-templates-serie-js": () => import("./../../../src/templates/serie.js" /* webpackChunkName: "component---src-templates-serie-js" */),
  "component---src-templates-words-single-word-js": () => import("./../../../src/templates/words/single-word.js" /* webpackChunkName: "component---src-templates-words-single-word-js" */),
  "component---src-templates-words-word-listing-js": () => import("./../../../src/templates/words/word-listing.js" /* webpackChunkName: "component---src-templates-words-word-listing-js" */)
}

