const API_URL =
  process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:8888/api'

const checkStatus = response => {
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}

export const sendPurchase = async (orderId, data) => {
  return fetch(`/api/kco-order/?order_id=${orderId}`, {
    method: 'post',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(res => res.json())
}

const checkFormStatus = response => {
  if (!response.ok) {
    // GravityForm error passed back to the form itself
    // throw new Error(response.statusText)
  }
  return response
}

export const sendForm = async (baseUrl, formData) => {
  let functionsData = {
    baseUrl: baseUrl,
    formData: formData,
  }
  return await await fetch(`${API_URL}/form`, {
    method: 'post',
    body: JSON.stringify(functionsData),
  })
    .then(checkFormStatus)
    .then(res => res.json())
}

/**
 * Get stock status
 */
export const getStockStatus = async () => {
  return await await fetch(
    `${process.env.GATSBY_CMS_URL}/wp-json/ecoride/v1/stock`,
    {
      method: 'GET',
    }
  )
    .then(checkStatus)
    .then(res => res.json())
}

/**
 * Read Klarna order
 */
export const readKlarnaOrder = async order_id => {
  return fetch(`/api/kco-order/?order_id=${order_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(res => res.json())
}

/**
 * Get trustpilot reviews
 */
export const getTrustpilotReviews = async () => {
  return await await fetch(
    `${process.env.GATSBY_CMS_URL}/wp-json/trustpilot/reviews`,
    {
      method: 'GET',
    }
  )
    .then(checkStatus)
    .then(res => res.json())
    .catch(e => Promise.reject(e))
}

/**
 * Get customer profile
 */
export const getCustomerProfile = async userId => {
  return await await fetch(
    `${process.env.GATSBY_CMS_URL}/wp-json/ecoride/v1/customer-profile?id=${userId}`,
    {
      method: 'GET',
    }
  )
    .then(checkStatus)
    .then(res => res.json())
    .catch(e => Promise.reject(e))
}

export const updateCustomerWP = async customer => {
  return await await fetch(
    `${process.env.GATSBY_CMS_URL}/wp-json/ecoride/v1/customer-profile`,
    {
      method: 'POST',
      body: JSON.stringify(customer),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(checkStatus)
    .then(res => res.json())
    .catch(e => Promise.reject(e))
}

export const getTranslationsRest = async () => {
  const response = await fetch(
    `${process.env.GATSBY_CMS_URL}/wp-json/ecoride/v1/translations`,
    {
      method: 'GET',
    }
  )

  const strings = await response.json()

  return strings
}
